const currentDate = new Date();
let currentYear = currentDate.getFullYear();

export const RegisterLabelConstants = {
    AlreadyHaveAccount: 'Already have an Account?',
    ConfirmPassword: 'Confirm Password',
    CopyRight: `© ${currentYear} All Rights Reserved | Superari Life`,
    Emailaddress: 'Email address',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Password: 'Password',
    PrivacyPolicy: 'Privacy Policy',
    TermsAndCondition: 'Term and Conditions',
    Register: 'Register',
    Signup: 'Sign up',
    Signin: 'Sign in',
    HealthConcern: 'Health Concern Note'
}

export const ForgotPassLabelConstants = {
    CopyRight: `© ${currentYear} All Rights Reserved | Superari Life`,
    Emailaddress: 'Email address',
    ForgotPass: 'Forgot Your Password',
    GoBack: 'Go To Back',
    PrivacyPolicy: 'Privacy Policy',
    TermsAndCondition: 'Term and Conditions',
    SendLink: 'Please enter your email address below to receive a password reset link.',
    Send: 'Send'
}

export const LoginLabelConstants = {
    CopyRight: `© ${currentYear} All Rights Reserved | Superari Life`,
    DontHaveAccount: "Don't have an account?",
    Emailaddress: 'Email address',
    Forgotpassword: 'Forgot password?',
    Password: 'Password',
    PrivacyPolicy: 'Privacy Policy',
    TermsAndCondition: 'Term and Conditions',
    Signin: 'Sign in',
    SignUpNow: 'Sign up now'
}

export const UnderMainatanceLabelConstant = {
    title: "Sorry!! We are under maintenance",
    mainParagraph: "Our website is currently undergoing some upgrades, we will be up and running soon.",
    paragraph: "Thank you for your patience."
}

export const TermsConditionLabelConstant = {
    title: "Superari Life Terms and Conditions"
}

export const PrivacyPolicyLabelConstant = {
    title: "Privacy Policy"
}

export const ShoppingCartLabelConstant = {
    home: "Home",
    shoppingCart: "Shopping cart",
    sessionsEvents: "Sessions | Events & Retreats",
    rate: "Rate",
    noOfParticipant: "No. Of Participant",
    subTotal: "Sub Total",
    hostedBy: "Hosted By",
    eventQuestions: "Event Questions",
    add: "Add",
    off: "off",
    tax: "TAX",
    discountCoupon: "Discount Coupon",
    total: "Total",
    next: "NEXT",
    deliveryOptions: "Delivery options",
    selectHowYouWilBeReceivingYourOrder: "Select how you’ll be receiving your order.",
    paymentInformation: "Payment information",
    choosePaymentMethodAndEnterYourPaymentDetails: "Choose a payment method and enter your payment details.",
    orderConfirmation: "Order confirmation",
    placeYourOrderAndReceiveConfirmationEmail: "Place your order and receive a confirmation email.",
    checkout: "Checkout",
    enterYourEmailAddressThisAddressWillBeUsedToSendYouOrderStatusUpdates: "If you want to gift this, type in email address of recipient.",
    contactEmail: "Contact Email",
    allDataIsTransmittedEncryptedViaSecureTLSConnection: "All data is transmitted encrypted via a secure TLS connection",
    proceedToCheckout: "Proceed To Checkout",
    yourCartIsEmpty: "Your cart is empty.",
    areyouSureyouWantToRemoveThis: "Are you sure you want to remove this?",
    yes: "Yes",
    no: "No"
}

export const SessionLabelConstant = {
    youAreSuccessfullyConfirmedYour: "You have successfully confirmed your ",
    ok: "Ok",
    bookSession: "Book A Session",
    superariYoga: "Superari Yoga",
    with: "with",
    passType: "Pass Type :",
    passUsed: "Pass Used :",
    sessionUsed: "Session Used :",
    passLeft: "Pass left :",
    sessionLeft: "Session left :",
    expiryDate: "Expiry Date :",
    confirm: "Confirm",
    passes: "PASSES",
    expireOn: "Expire On :",
    noOfSessions: "No. Of Sessions :",
    purchaseOn: "Purchase On",
    ExpireOn: "Expire On",
    passesUsed: "Session Used",
    passesLeft: "Session Left",
    noPassesAvailable: "No passes available.",
    addToCart: "Add To Cart",
    checkOut: "Check Out",
    view: "View",
    book: "Book",
    booked: "Booked",
    soldOut: "Sold Out",
    noRecordsFound: "No records found!",
    sessionDetails: "SESSION DETAILS",
    ifYouHaveAnyQuestionsPleaseEmail: "If you have any questions please email",
    rakheeEmail: "rakhee@superarilife.com",
    alwaysHappyToHelp: "always happy to help!",
    upcomingSession: "UPCOMING WEEKLY CLASSES",
    noUpcomingSessionsAvailable: "No upcoming weekly classes available !",
    viewAll: "View all",
    bookedSessions: "BOOKED WEEKLY CLASSES",
    earlyCancel: "Early Cancel",
    cancelled: "Cancelled",
    noBookedSessions: "No booked  weekly classes !",
    pleaseSignInToSeeBookedSessions: "Please sign in to see booked sessions.",
    noBookedSessionRecordFound: "No any booked sessions record found!"
}

export const ResetPasswordLabelConstant = {
    resetPassword: "Reset Password",
    pleaseEnterAndConfirmNewPassword: "Please enter and confirm a new password.",
    password: "Password",
    confirmPassword: "Confirm Password",
    thisLinkIsExpiredPleaseResendLinkToResetPassword: "This link is expired. please resend link to reset password",
    goToBack: "Go To Back"
}

export const PaymentSuccessLabelConstant = {
    home: "HOME",
    payment: "PAYMENT",
    paymentSuccessful: "Payment Successful!",
    orderSuccessfulText: "Your Order was placed successfully. We’re",
    orderSuccessfullpara: "working hard to get it to you soon.",
    backToHome: "Back To Home"
}

export const OTPVerifyLabelConstant = {
    twoStepVerification: "Two Step Verification",
    sixxDigitOTP: "Please enter the 6 digit code sent to",
    enterCodeBelow: "Enter Code Below",
    submit: "Submit",
    goToBack: "Go To Back",
    spamCheck: "Please check the spam folder if you can't find the email in your inbox."
}

export const MyAddressLabelConstant = {
    myAddress: "MY ADDRESSES",
    update: "Update",
    noAddressFound: "No address found !"
}

export const HomeLabelConstant = {
    welcome: "WELCOME TO SUPERARI LIFE",
    firstPara: "Superari is derived from the Latin word Superare, which means the will to overcome. It is a belief, a knowing, a spirit which exists in each of us that no matter what happens we have the physical, spiritual, emotional and mental capability to overcome anything. Superari was founded by Rakhee",
    yogi: "(@yogi_rakhee)",
    secondPara: "who is a passionate Yoga teacher. Through her classes she encourages people to practice, learn and live in a way which helps strengthen their self-belief and resolve in day to day life. She teaches all over London as well as at companies including Nike.",
    sessions: "Weekly Classes",
    events: "Events & Retreats"
}

export const ContactUsLabelConstant = {
    title: "We’d love to hear from you. Just choose the most convenient method and we’ll get back to you as soon as we can or press on any of the social media tabs below and drop us a DM.",
    locationTitle: "OUR LOCATION",
    location: "123 Fifth Avenue, New York, NY 12004, United Kingdom.",
    contactTitle: "Contact number",
    contactNumber: "+ 44 (0) 7841 849 725",
    emailTitle: "EMAIL US",
    email: "rakhee@superarilife.com",
    contactUs: "Contact Us",
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email address",
    phoneNumber: "Phone Number",
    message: "Message",
    chechboxTitle: "By submitting this form, you agree to us contacting you regarding the enquiry and agree to our ",
    privacyPolicy: "Privacy Policy",
    send: "Send"
}

export const CheckoutLabelConstant = {
    home: "HOME",
    checkout: "CHECKOUT",
    conatctInfo: "CONTACT INFO",
    deafult: "Default",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    contactNumbr: "Contact Number",
    billingAddress: "BILLING ADDRESS",
    address: "Address Line 1",
    state: "Address Line 2",
    city: "Town/City",
    country: "Country",
    zipCode: "Zip/Postal Code",
    cardInfo: "CARD INFO",
    sessionEvent: "Sessions | Events & Retreats",
    rate: "Rate",
    subTotal: "Sub Total",
    hostedBy: "Hosted By -",
    qty: "Qty:",
    apply: "Apply",
    off: "off",
    tax: "TAX",
    discountCoupon: "Discount Coupon",
    total: "Total",
    makePayment: "Make A Payment",
    PaymentSuccess: "Payment Successful!",
    paymentSuccesstext: "Your Order was placed successfully. We’re working hard to get it to you soon.",
    yes: "Yes"
}

export const AboutUsLabelConstant = {
    addVideoCall: "Add 15 Minutes Video Call"
}

export const EventLabelConstant = {
    events: "Events & Retreats",
    next: "Next",
    addTocart: "Add To Cart",
    eventSoldOut: "This event is sold out.",
    eventLocation: "Event & Retreats Location",
    eventQuestion: "Event & Retreats Question",
    updateQuestions: "Update Questions",
    event: "EVENTS & RETREATS",
    soldout: "SOLD OUT",
    booked: "BOOKED",
    bookNow: "BOOK NOW"
}

export const MyProfileLabelConstant = {
    firstName: "First Name",
    lastName: 'Last Name',
    email: "Email",
    mobileNumber: "Mobile Number",
    sessionLeft: "Sessions Left",
    address: "Address Line 1",
    state: 'Address Line 2',
    city: 'Town/City',
    country: 'Country',
    zipCode: "Zip/Postal Code",
    makeDefault: "Make as a default",
    areYouSureWantToRefundForEvent: "Are you sure you want to refund from this events & retreats?",
    qty: "Qty:",
    quantity: "Quantity",
    transactionId: "Transaction Id",
    refundedAmount: "Refunded Amount",
    writeMessageForEvenetRefund: "Write a valid message to get refund from this events & retreats.",
    refundQuantity: "Refund Quantity",
    wrietMessage: "Write a message",
    adminReason: "Admin reason",
    submit: "Submit",
    cancel: "Cancel",
    refundResponseTitle: 'Are you sure requesting for refund for',
    Quantity: "quantity?",
    refundResponsetext: "The money will be sent to your credit card account within 4 working days.",
    // refundResponseSuccess: "Your request for reporting this event has been initiated successfully.",
    ok: "OK",
    areYouSureWantToReportEvent: "Are you sure you want to report this events & retreats?",
    writeMessageForReportEvent: "Write a valid message to report this events & retreats.",
    adminResponse: "Admin Response",
    send: "Send",
    reportDescription: "Report Description",
    writeReportWeWillSolveIt: "Please write your report and we will solve it out asap.",
    hostedBy: "Hosted by -",
    areYouSureWantToReport: "Are you sure you want to report?",
    reported: " Reported",
    the: "The",
    reportSuccessText: "has been reported successfully we will look into this matter and contact you.",
    myProfile: "My Profile",
    MYPROFILE: "MY PROFILE",
    update: "Update",
    myAddress: "My address",
    MYADDRESSES: "MY ADDRESSES",
    addNew: "Add New",
    default: "Default",
    noAddressFound: "No address found!",
    passes: "Passes",
    PASSES: "PASSES",
    superariYoga: "Superari Yoga",
    withRakhee: "- with Rakhee Vithlani",
    attended: "Attended",
    sessions: "Sessions",
    passesLeft: "Sessions Left",
    purchaseOn: "Purchase On :",
    expireOn: "Expire On :",
    noOfSessions: "No. Of Sessions :",
    attendedSessionsList: "Sessions List",
    yogaInstructor: "Yoga Instructor -",
    report: "Report",
    noSessionsFound: "No sessions found!",
    noPassesRecordFound: "No passes record found!",
    purchase: "Purchase",
    purchaseOfEvent: "Events & Retreats",
    PURCHASES: "EVENTS & RETREATS",
    view: "View",
    TransactionDetails: "TRANSACTION DETAILS",
    transactionTime: "Transaction Time : ",
    transactionID: "Transaction ID : ",
    paymentMode: "Payment Mode : ",
    details: "DETAILS",
    eventQuestions: "EVENT & RETREATS QUESTIONS",
    location: "LOCATION",
    noPurchaseRecordFound: "No events & retreats record found!",
    changePassword: "Change password",
    CHANGEPASSWORD: "CHANGE PASSWORD",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    healthConcern: "Health Concern Note",
    paymentType: "Payment Type : "
}

export const FooterLabelConstant = {
    testimonial: "Testimonial",
    whatPeopleSayAboutUs: "What people say about us",
    allRightReserved: "All Rights Reserved | Superari Life",
    privacyPolicy: "Privacy policy",
    termsConditions: "Terms & Conditions"
}

export const HeaderLabelConstant = {
    about: "About",
    current: "(current)",
    sessions: "Weekly Classes",
    events: "Events & Retreats",
    contact: "Contact",
    signIn: "Sign In",
    myAccount: "My Account",
    logout: "Logout"
}

export const LogoutModalLabelConsant = {
    areYouSureYouWantTo: "Are you sure you want to",
    Logout: "Logout",
    logout: "Logout?",
    cancel: "Cancel"
}

export const commonLabelConstants = {
    goBack: "Go Back",
    recordsPerPage: "Records Per Page",
    showing: "Showing",
    of: "of",
    yes: "Yes",
    no: "No"
}

export const CurrencyLogo = {
    BritishPound: '£',
    Percentage: "%",
    NoData: 'No any events & retreats found !'
}

export const DateFormats = {
    DDMMYY: 'dd-MM-yyyy',
    Time: 'h:mma',
    MonthAndDay: 'EEEE, MMMM d',
    MonthAndYear: 'MMM , y',
    DayAndDate: 'EEE, d',
    shortTime: 'h:mma', //'h:mm a'
    medium: 'medium' //'MMM d, y, h:mm:ss a'
}

export interface GalleryImage {
    eventGalleryImagePath: string;
    eventGalleryImageName: string;
    selected?: boolean; // Optional property for selection status
}
